import {MemoryTagDto} from "gastromemories-api-client";
import {Select, Form, Button, Space, Flex} from "antd";
import React from "react";

export interface IMemoriesFilters {
    tags?: MemoryTagDto[];
    search?: string;
    year?: number;
    month?: number;
    day?: number;
    xMin?: number;
    xMax?: number;
    yMin?: number;
    yMax?: number;
}

export function Filters({memoryTags, onCloseClick, onClearAllClick, onApplyClick, filters}: {
    memoryTags: MemoryTagDto[],
    onCloseClick: () => void,
    onClearAllClick: () => void,
    onApplyClick: (values: any) => void,
    filters: IMemoriesFilters
}) {
    return <>
        <div style={{paddingLeft: "12px", paddingRight: "12px", width: "100%"}}>
            <Form
                scrollToFirstError
                title={"Filters"}
                onFinish={(values) => onApplyClick({...filters, ...values})}
                style={{paddingBlock: "16px"}}
                labelCol={{span: 6}}
                wrapperCol={{span: 14}}
                initialValues={{
                    ...filters,
                    tags: filters.tags?.map(tag => tag.name)
                }}
            >
                <h3>Filters</h3>
                <Form.Item name="tags" label="Tags" rules={[{type: 'array'}]}>
                    <Select
                        mode="multiple"
                        placeholder="Select tags"
                        allowClear
                        style={{width: '100%'}}
                    >
                        {memoryTags.map(tag => <Select.Option key={tag.id} value={tag.name}>{tag.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item name="year" label="Year">
                    <Select
                        placeholder="Select year"
                        allowClear
                        style={{width: '100%'}}
                    >
                        {Array.from({length: new Date().getFullYear() - 2000 + 1}, (_, i) => new Date().getFullYear() - i).map(year =>
                            <Select.Option key={year} value={year}>{year}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item name="month" label="Month">
                    <Select
                        placeholder="Select month"
                        allowClear
                        style={{width: '100%'}}
                    >
                        {Array.from({length: 12}, (_, i) => i + 1).map(month => <Select.Option key={month}
                                                                                               value={month}>{month}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item name="day" label="Day">
                    <Select
                        placeholder="Select day"
                        allowClear
                        style={{width: '100%'}}
                    >
                        {Array.from({length: 31}, (_, i) => i + 1).map(day => <Select.Option key={day}
                                                                                             value={day}>{day}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Flex justify={"center"}>
                    <Space size="large">
                    <Button type="primary" htmlType="submit">
                        Apply
                    </Button>
                    <Button onClick={onCloseClick}>
                        Cancel
                    </Button>
                    <Button onClick={onClearAllClick}>
                        Clear all
                    </Button>
                </Space>
                </Flex>
            </Form>

        </div>
    </>
}