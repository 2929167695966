import {Button, Col, Flex, FloatButton, Input, Layout, List, Radio, Row, Skeleton, Space, Spin} from 'antd'
import {FilterOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';
import {Buffer} from 'buffer';
import React, {useEffect} from "react";
import Webcam from "react-webcam"
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    CreateImageCommand, CreateImageTagSuggestionCommand,
    CreateMemoryCommand,
    ImageDto,
    ImagesClient, ImageTagSuggestionsClient,
    ImageTypeEnum,
    MemoriesClient,
    MemoryDto,
    MemoryTagDto,
    MemoryTagsClient
} from "gastromemories-api-client";
import MemoryListItem from "./components/MemoryListItem";
import MemoryWebcam from "./components/MemoryWebcam";
import MemoryForm, {MemoryFormMode} from "./components/CreateMemoryForm";
import {useAuth0} from "@auth0/auth0-react";
import {Filters, IMemoriesFilters} from "./components/Filters";
import {contentStyle, headerStyle, layoutStyle} from "./components/LayoutStyles";

const {Header, Content} = Layout;
const memoriesPageSize = 3


const enum Step {
    Filters = "filters",
    List = "list",
    Photo = "photo",
    MemoryCreateForm = "memoryCreateForm",
    MemoryUpdateForm = "memoryUpdateForm",
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const App = () => {
    const {getAccessTokenSilently, isLoading, isAuthenticated, loginWithRedirect} = useAuth0();
    const webcamRef = React.useRef<Webcam | null>(null);
    const [currentStep, setCurrentStep] = React.useState<Step>(Step.List);
    const [memories, setMemories] = React.useState<MemoryDto[]>([]);
    const [orientation, setOrientation] = React.useState<string>("portrait-primary");
    const [width, setWidth] = React.useState<number>(window.screen.width);
    const [height, setHeight] = React.useState<number>(window.screen.height);
    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    const [memoryTags, setMemoryTags] = React.useState<MemoryTagDto[]>([]);
    const [selectedMemory, setSelectedMemory] = React.useState<MemoryDto | null>(null);
    const [suggestedTagsTryCounter, setSuggestedTagsTryCounter] = React.useState<number>(0);
    const [imageTagSuggestionId, setImageTagSuggestion] = React.useState<string | null>(null);
    const [location, setLocation] = React.useState<GeolocationCoordinates | null>(null);
    const [capturedImageSrc, setCapturedImageSrc] = React.useState<string | null>(null);
    const [currentPage, setCurrentPage] = React.useState<number>(2);
    const [hasMoreMemories, setHasMoreMemories] = React.useState<boolean>(true);
    const [shouldReinitialize, setShouldReinitialize] = React.useState<boolean>(true);
    const [filters, setFilters] = React.useState<IMemoriesFilters>({
        tags: [],
        search: undefined,
        year: undefined,
        month: undefined,
        day: undefined,
        xMin: undefined,
        xMax: undefined,
        yMin: undefined,
        yMax: undefined
    });
    const [createMemoryCommand, setCreateMemoryCommand] = React.useState<CreateMemoryCommand>(new CreateMemoryCommand({
        title: "",
        note: "",
        image: new ImageDto({
            id: "",
            ownerId: "",
            url: "",
            type: ImageTypeEnum.TaggableFood,
        }),
        tags: [],
        latitude: 0,
        longitude: 0,
        tastinessScore: 0
    }));

    window.screen.orientation.addEventListener('change', function (e) {
        setOrientation(window.screen.orientation.type)
        setWidth(window.screen.width);
        setHeight(window.screen.height);
    })

    const refreshMemoriesAndTags = React.useCallback(async () => {
        if (!isAuthenticated) {
            return;
        }
        try {
            const accessToken = await getAccessTokenSilently();

            const client = new MemoriesClient(baseUrl, {
                fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                    return fetch(url, {
                        ...init,
                        headers: {
                            ...init?.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
            });

            const getMemoriesWithPaginationResponse = await client.getMemoriesWithPagination(
                currentPage,
                memoriesPageSize,
                filters.tags ? filters.tags.map(tag => tag.id!) : undefined,
                filters.search,
                filters.year,
                filters.month,
                filters.day,
                filters.xMin,
                filters.xMax,
                filters.yMin,
                filters.yMax
            )
            setMemories(memories => [...memories, ...getMemoriesWithPaginationResponse.items!])
            setCurrentPage(currentPage => currentPage + 1)
            setHasMoreMemories(getMemoriesWithPaginationResponse.hasNextPage!)
        } catch (e: any) {
            console.log(e.message);
        } finally {
        }
    }, [getAccessTokenSilently, isAuthenticated, filters, currentPage]);

    useEffect(() => {
        const handleLogin = async () => {
            if (!isLoading && !isAuthenticated) {
                await loginWithRedirect();
            }
        }
        handleLogin()
    }, [isAuthenticated, isLoading, loginWithRedirect]);

    const transformTagNamesIntoTags = React.useCallback((tagNames: string[]): MemoryTagDto[] => {
        return tagNames.map((tag: string) => {
            const tagInUserTags = memoryTags.find((memoryTag: MemoryTagDto) => memoryTag.name === tag);
            return new MemoryTagDto({
                id: tagInUserTags?.id || undefined,
                name: tag,
            });
        });
    }, [memoryTags])

    const reinitializeMemoriesAndTags = React.useCallback(async () => {
        setMemories([])
        setMemoryTags([])
        setCurrentPage(2)
        setShouldReinitialize(true)
    }, [])

    const createMemoryFormSubmit = React.useCallback(async (values: any) => {
        setIsUploading(true);
        values.tags = transformTagNamesIntoTags(values.tags)
        const accessToken = await getAccessTokenSilently();

        try {
            const client = new MemoriesClient(baseUrl, {
                fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                    return fetch(url, {
                        ...init,
                        headers: {
                            ...init?.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
            });
            await client.createMemory({
                ...values,
                longitude: location?.longitude,
                latitude: location?.latitude,
            });
        } catch (e: any) {
            console.log(e.message);
        }
        setIsUploading(false);
        setCurrentStep(Step.List)
        await reinitializeMemoriesAndTags()
    }, [getAccessTokenSilently, transformTagNamesIntoTags, location, reinitializeMemoriesAndTags]);

    const updateMemoryFormSubmit = React.useCallback(async (values: any) => {
        setIsUploading(true);
        values.tags = transformTagNamesIntoTags(values.tags)
        const accessToken = await getAccessTokenSilently();

        try {
            const client = new MemoriesClient(baseUrl, {
                fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                    return fetch(url, {
                        ...init,
                        headers: {
                            ...init?.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
            });
            await client.updateMemory(values.id!, {
                ...values,
                longitude: 0,
                latitude: 0,
            });
        } catch (e: any) {
            console.log(e.message);
        }
        setIsUploading(false);
        setCurrentStep(Step.List)
        await reinitializeMemoriesAndTags()
    }, [getAccessTokenSilently, transformTagNamesIntoTags, reinitializeMemoriesAndTags]);

    const deleteMemoryFormSubmit = React.useCallback(async (values: any) => {
        setIsUploading(true);

        const accessToken = await getAccessTokenSilently();

        try {
            const client = new MemoriesClient(baseUrl, {
                fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                    return fetch(url, {
                        ...init,
                        headers: {
                            ...init?.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
            });
            await client.deleteMemory(values.id!);
        } catch (e: any) {
            console.log(e.message);
        }
        setIsUploading(false);
        setCurrentStep(Step.List)
        await reinitializeMemoriesAndTags()
    }, [getAccessTokenSilently, reinitializeMemoriesAndTags]);

    const capture = React.useCallback(async () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (webcamRef.current === null || webcamRef.current?.video === null) {
            return
        }

        webcamRef.current?.video.pause()

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLocation(position.coords);
            });
        }

        if (imageSrc) {
            setCapturedImageSrc(imageSrc);
            const resizeImage = (base64Str: string, scale: number): Promise<string | null> => {
                return new Promise((resolve) => {
                    let img = new Image()
                    img.src = base64Str
                    img.onload = () => {
                        let canvas = document.createElement('canvas')
                        let width = img.width * scale
                        let height = img.height * scale

                        canvas.width = width
                        canvas.height = height
                        let ctx = canvas.getContext('2d')
                        if (ctx === null) {
                            resolve(null)
                            return
                        }
                        ctx.drawImage(img, 0, 0, width, height)
                        resolve(canvas.toDataURL())
                    }
                })
            }

            setIsUploading(true);

            const resizedImageSrc = await resizeImage(imageSrc, 1 / 4)
            if (resizedImageSrc === null) {
                console.log("Failed to resize the image")
                return
            }

            try {
                const accessToken = await getAccessTokenSilently();

                const client = new ImagesClient(baseUrl, {
                    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                        return fetch(url, {
                            ...init,
                            headers: {
                                ...init?.headers,
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                    }
                });

                const imageTagSuggestionsClient = new ImageTagSuggestionsClient(baseUrl, {
                    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                        return fetch(url, {
                            ...init,
                            headers: {
                                ...init?.headers,
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                    }
                });

                const imageId = await client.createImage(new CreateImageCommand({
                    type: ImageTypeEnum.TaggableFood,
                }))

                const imageUploadUrlDto = await client.createUploadUrlForImage(imageId)

                const buffer = Buffer.from(resizedImageSrc.split(",")[1], 'base64');

                await fetch(imageUploadUrlDto.transcodedUrl!, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'image/webp',
                    },
                    body: buffer
                })

                const image = await client.getImage(imageId)

                const imageTagSuggestionId = await imageTagSuggestionsClient.createImageTagSuggestion(new CreateImageTagSuggestionCommand({
                    imageId: imageId,
                }));

                setImageTagSuggestion(imageTagSuggestionId)

                setSuggestedTagsTryCounter(0);
                setCreateMemoryCommand(new CreateMemoryCommand({
                    ...createMemoryCommand,
                    image,
                }))

                const worker = new Worker(new URL('./longProcesses/file-upload.ts', import.meta.url), {type: 'module'});
                worker.postMessage({
                    file: imageSrc,
                    uploadUrl: imageUploadUrlDto.url,
                    httpMethod: 'PUT',
                    headers: {
                        'Content-Type': 'image/webp',
                    }
                });

                setCurrentStep(Step.MemoryCreateForm);
            } catch (e: any) {
                setCurrentStep(Step.List)
            }
            setIsUploading(false);
        }
    }, [getAccessTokenSilently, createMemoryCommand]);

    useEffect(() => {
        if (!createMemoryCommand.image?.id) {
            // Initial state has no image id, because no image has been uploaded
            return;
        }

        if (createMemoryCommand.image?.suggestedTags) {
            // If there are suggestions, then this effect has already run correctly to the finish
            return;
        }

        if (suggestedTagsTryCounter >= 30) {
            // If we tried 30 times, then stop trying
            return;
        }

        const interval = setInterval(async () => {
                setSuggestedTagsTryCounter(suggestedTagsTryCounter + 1)
                if (createMemoryCommand.image && createMemoryCommand.image.suggestedTags === undefined) {
                    const accessToken = await getAccessTokenSilently();

                    const imageTagSuggestionsClient = new ImageTagSuggestionsClient(baseUrl, {
                        fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                            return fetch(url, {
                                ...init,
                                headers: {
                                    ...init?.headers,
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            });
                        }
                    });

                    const imageTagSuggestionDto = await imageTagSuggestionsClient.getImageTagSuggestion(imageTagSuggestionId!)
                    setCreateMemoryCommand(new CreateMemoryCommand({
                        ...createMemoryCommand,
                        image: {
                            ...createMemoryCommand.image,
                            suggestedTags: imageTagSuggestionDto.suggestedTags
                        } as ImageDto
                    }))
                }
            }
            , 2000);
        return () => {
            clearInterval(interval);
        };

    }, [getAccessTokenSilently, createMemoryCommand, suggestedTagsTryCounter, capture, imageTagSuggestionId]);

    useEffect(() => {
        const initializeMemoriesAndTags = async () => {
            if (!isAuthenticated && shouldReinitialize) {
                return;
            }
            setShouldReinitialize(false);

            try {
                const accessToken = await getAccessTokenSilently();

                const client = new MemoriesClient(baseUrl, {
                    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                        return fetch(url, {
                            ...init,
                            headers: {
                                ...init?.headers,
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                    }
                });

                const getMemoriesWithPaginationResponse = await client.getMemoriesWithPagination(
                    1,
                    memoriesPageSize,
                    filters.tags ? filters.tags.map(tag => tag.id!) : undefined,
                    filters.search,
                    filters.year,
                    filters.month,
                    filters.day,
                    filters.xMin,
                    filters.xMax,
                    filters.yMin,
                    filters.yMax
                )
                setMemories(getMemoriesWithPaginationResponse.items!)
                setHasMoreMemories(getMemoriesWithPaginationResponse.hasNextPage!)

                const memoryTagsClient = new MemoryTagsClient(baseUrl, {
                    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
                        return fetch(url, {
                            ...init,
                            headers: {
                                ...init?.headers,
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                    }
                });

                const memoriesTags = await memoryTagsClient.getMemoryTagsWithPagination(1, 500)
                setMemoryTags(memoriesTags.items!)
            } catch (e: any) {
                console.log(e.message);
            } finally {
            }
        }
        initializeMemoriesAndTags()
    }, [getAccessTokenSilently, filters, isAuthenticated, shouldReinitialize]);

    return <>
        <div style={{opacity: isUploading ? 0.5 : 1}}>
            <Spin spinning={isUploading} style={{position: "absolute", top: '50%', left: 'calc(50% - 20px)'}}
                  size="large"/>
            {(currentStep === Step.List || currentStep === Step.MemoryCreateForm || currentStep === Step.MemoryUpdateForm || currentStep === Step.Filters) && (
                <>
                    <Flex gap="middle" wrap="wrap" vertical>
                        <Layout style={layoutStyle}>
                            <Header style={headerStyle}>gastromemories</Header>
                            <Content style={contentStyle}>
                                <Space direction="vertical" style={{width: '100%'}} size="middle">
                                    <>
                                        {currentStep === Step.List && (
                                            <>
                                                <Row gutter={[16, 16]} style={{padding: "16px 12px 0px 12px"}}>
                                                    <Col span={20} offset={0}>
                                                        <Input
                                                            placeholder="Search"
                                                            prefix={<SearchOutlined/>}
                                                            onChange={(e) => setFilters({
                                                                ...filters,
                                                                search: e.currentTarget.value
                                                            })}
                                                        />
                                                    </Col>
                                                    <Col
                                                        span={2}><Button
                                                        onClick={() => setCurrentStep(Step.Filters)}><FilterOutlined></FilterOutlined></Button></Col>
                                                </Row>
                                                    <Row gutter={[16, 16]} style={{padding: "0px 12px 0px 12px"}}>
                                                    <Col span="24">
                                                        <Radio.Group buttonStyle="solid" defaultValue="list"
                                                                     style={{width: "100%", textAlign: "center"}}>
                                                            <Radio.Button value="list" style={{
                                                                width: "50%",
                                                                borderBottomLeftRadius: '6px',
                                                                borderTopLeftRadius: '6px'
                                                            }}>List</Radio.Button>
                                                            <Radio.Button value="map" style={{
                                                                width: "50%",
                                                                borderBottomRightRadius: '6px',
                                                                borderTopRightRadius: '6px'
                                                            }}>Map</Radio.Button>
                                                        </Radio.Group>
                                                    </Col>
                                                </Row>
                                                <InfiniteScroll
                                                    dataLength={memories.length}
                                                    next={refreshMemoriesAndTags}
                                                    hasMore={hasMoreMemories}
                                                    loader={<Skeleton avatar paragraph={{rows: 1}} active/>}
                                                    scrollableTarget="scrollableDiv"
                                                    scrollThreshold={0.95}
                                                >
                                                    <List
                                                        dataSource={memories}
                                                        renderItem={(item: MemoryDto) => (
                                                            <List.Item key={item.id} style={{paddingTop: "0px", paddingBottom: "0px"}}>
                                                                <MemoryListItem
                                                                    onEditClick={(memory: MemoryDto) => {
                                                                        setSelectedMemory(memory);
                                                                        setCurrentStep(Step.MemoryUpdateForm)
                                                                    }}
                                                                    onTagClick={async (e, tagId: string) => {
                                                                        e.stopPropagation()
                                                                        let newTags = filters.tags;
                                                                        if (!newTags) {
                                                                            newTags = [];
                                                                        }
                                                                        // If tag already in filters, remove it
                                                                        if (newTags.map(tag => tag.id).includes(tagId)) {
                                                                            newTags = newTags.filter((t: MemoryTagDto) => t.id !== tagId);
                                                                        } else {
                                                                            newTags.push(memoryTags.find(tag => tag.id === tagId)!);
                                                                        }
                                                                        setFilters({...filters, tags: newTags});
                                                                        await reinitializeMemoriesAndTags()
                                                                    }}
                                                                    filters={filters}
                                                                    memoryListItem={item}/>
                                                            </List.Item>
                                                        )}/>
                                                </InfiniteScroll>
                                                <FloatButton
                                                    shape="circle"
                                                    type="primary"
                                                    onClick={() => setCurrentStep(Step.Photo)}
                                                    icon={<PlusOutlined/>
                                                    }
                                                />
                                            </>
                                        )}
                                        {currentStep === Step.Filters && (
                                            <Filters
                                                memoryTags={memoryTags}
                                                onCloseClick={async () => {
                                                    setCurrentStep(Step.List)
                                                    await reinitializeMemoriesAndTags()
                                                }}
                                                onClearAllClick={async () => {
                                                    setFilters({
                                                        tags: [],
                                                        search: undefined,
                                                        year: undefined,
                                                        month: undefined,
                                                        day: undefined,
                                                        xMin: undefined,
                                                        xMax: undefined,
                                                        yMin: undefined,
                                                        yMax: undefined
                                                    })
                                                    setCurrentStep(Step.List)
                                                    await reinitializeMemoriesAndTags()
                                                }}
                                                onApplyClick={async (values: any) => {
                                                    let filters = {
                                                        tags: values.tags.map((tag: string) => memoryTags.find((memoryTag: MemoryTagDto) => memoryTag.name === tag)),
                                                        search: values.search,
                                                        year: values.year,
                                                        month: values.month,
                                                        day: values.day,
                                                        xMin: values.xMin,
                                                        xMax: values.xMax,
                                                        yMin: values.yMin,
                                                        yMax: values.yMax
                                                    }
                                                    setFilters(filters)
                                                    setCurrentStep(Step.List)
                                                    await reinitializeMemoriesAndTags()
                                                }}
                                                filters={filters}
                                            ></Filters>
                                        )}
                                        {currentStep === Step.MemoryCreateForm && (
                                            <MemoryForm onCloseClick={() => setCurrentStep(Step.List)}
                                                        onSubmitClick={createMemoryFormSubmit}
                                                        capturedImageSrc={capturedImageSrc || ""}
                                                        mode={MemoryFormMode.create}
                                                        memoryTags={memoryTags}
                                                        memory={createMemoryCommand}/>
                                        )}
                                        {currentStep === Step.MemoryUpdateForm && (
                                            <MemoryForm onCloseClick={() => setCurrentStep(Step.List)}
                                                        onSubmitClick={updateMemoryFormSubmit}
                                                        onDeleteClick={deleteMemoryFormSubmit}
                                                        capturedImageSrc={null}
                                                        mode={MemoryFormMode.update}
                                                        memoryTags={memoryTags}
                                                        memory={selectedMemory as MemoryDto}/>
                                        )}
                                    </>
                                </Space>
                            </Content>
                        </Layout>
                    </Flex>

                </>
            )}
            {currentStep === Step.Photo && (
                <>
                    <MemoryWebcam onCaptureClick={capture} onCloseClick={() => setCurrentStep(Step.List)}
                                  webcamRef={webcamRef}
                                  orientation={orientation} width={width} height={height}/>
                </>
            )}
        </div>
    </>
};

export default App;