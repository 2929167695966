import React from 'react';
import { Layout, Typography } from 'antd';
import {contentStyle, headerStyle, layoutStyle} from "./LayoutStyles";

const { Content, Header } = Layout;
const { Title, Paragraph } = Typography;


const DataDeletionRequest: React.FC = () => {
    return (
        <Layout style={layoutStyle}>
            <Header style={headerStyle}>gastromemories</Header>
            <Content style={contentStyle}>
                <div style={{ background: '#fff', padding: 24, minHeight: 380 }}>
                    <Title level={4}>Data Deletion Request</Title>
                    <Paragraph>
                        If you wish to delete your data from Gastromemories app, please follow the steps below:
                    </Paragraph>
                    <Paragraph>
                        1. Send an email to <a href="mailto:data-deletion-requests@gastromemories.com">data-deletion-requests@gastromemories.com</a> with the subject line "Data Deletion Request".
                    </Paragraph>
                    <Paragraph>
                        2. In the body of the email, please include the following information:
                        <ul>
                            <li>Your full name</li>
                            <li>Your email address associated with the app</li>
                            <li>Your Facebook username or ID</li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        3. Once we receive your email, we will process your request and delete all associated data within 30 days. You will receive a confirmation email once your data has been deleted.
                    </Paragraph>
                    <Title level={4}>Important Information</Title>
                    <Paragraph>
                        Ensure that you use the email address that you used to register for our app when making the data deletion request.
                        Deleting your data will remove all your stored food images and personal diary entries permanently.
                    </Paragraph>
                    <Paragraph>
                        If you have any questions or need further assistance, please contact our support team at <a href="mailto:support@gastromemories.com">support@gastromemories.com</a>.
                    </Paragraph>
                    <Paragraph>Thank you for using Gastromemories.</Paragraph>
                </div>
            </Content>
        </Layout>
    );
};

export default DataDeletionRequest;