import {Card, Flex, Image, Row, Space, Tag, Typography} from "antd";
import React from "react";
import {MemoryDto} from "gastromemories-api-client";
import {IMemoriesFilters} from "./Filters";

const gridStyle: React.CSSProperties = {
    width: '100%',
    textAlign: 'center',
    padding: '0px 0px 16px 0px',
    overflow: 'hidden',
};

export default function MemoryListItem({memoryListItem, onEditClick, onTagClick, filters}: {
    memoryListItem: MemoryDto, onEditClick: (memory: MemoryDto) => void,
    onTagClick(e: any, tag: string | undefined): void, filters: IMemoriesFilters
}) {
    return <Card bordered={false} style={{width: '100%'}} key={memoryListItem.id} onClick={() => onEditClick(memoryListItem)}>
        <Card.Grid style={gridStyle}>
            <Space direction="vertical" style={{width: '100%'}}>
                <Row>
                    <Image
                        width='100%'
                        className="food-img"
                        src={memoryListItem.image?.transcodedUrl ?? memoryListItem.image?.url}
                        preview={false}
                        fallback={memoryListItem.image?.url}
                        style={{objectFit: 'contain'}}
                    />
                </Row>
                <Row style={{paddingLeft: "12px", paddingRight: "12px"}}>
                    <Space direction="vertical">
                        <Row>
                            <Typography.Text style={{
                                margin: '0px',
                                fontWeight: "bold"

                            }}>{memoryListItem.title} - {memoryListItem.created?.toDateString()}</Typography.Text>
                        </Row>
                        {memoryListItem.note && <Row>
                            <Typography.Text>{memoryListItem.note}</Typography.Text>
                        </Row>}
                        {memoryListItem.tags && memoryListItem.tags.length > 0 && (
                            <>
                                <Row>
                                    <Flex gap="4px 0" wrap="wrap">
                                        {memoryListItem.tags?.map(tag =>
                                            <Tag
                                                key={tag.id}
                                                color={filters.tags?.map(tag => tag.id).includes(tag.id) ? '#F9DB6D' : 'default'}
                                                style={{color: filters.tags?.map(tag => tag.id).includes(tag.id) ? '#000000' : '#000000'}}
                                                onClick={(e) => onTagClick(e, tag.id)}>
                                                <Typography.Text>#{tag.name}</Typography.Text>
                                            </Tag>)
                                        }
                                    </Flex>
                                </Row>
                            </>
                        )}
                    </Space>
                </Row>
            </Space>
        </Card.Grid>
    </Card>
}