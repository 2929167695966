import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import {ConfigProvider} from "antd";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import DataDeletionRequest from "./components/DataDeletionRequest";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://268528acc28eb5833211ffa3501fc1a9@o4507305961848832.ingest.de.sentry.io/4507305965125712",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/api\.gastromemories\.com\//],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/data-deletion-request",
        element: <DataDeletionRequest/>,
    },
]);

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";

root.render(
    <ConfigProvider
        theme={{
            components: {
                Radio: {
                    colorPrimary: '#000000',
                    colorPrimaryHover: '#000000',
                },
                FloatButton: {
                    colorPrimary: '#000000',
                    colorPrimaryHover: '#000000',
                },
                Spin: {
                    colorPrimary: '#000000',
                    colorPrimaryHover: '#000000',
                },
                Button: {
                    colorPrimary: '#000000',
                    colorPrimaryHover: '#000000',
                },
                Layout: {
                    headerBg: '#F9DB6D',
                    footerBg: '#F9DB6D',
                    bodyBg: '#ffffff',
                },
                Tag: {
                    defaultBg: "#FFFFFF",
                }

            },

        }}
    >
        <Auth0Provider
            domain={auth0Domain}
            clientId={auth0ClientId}
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: "read:memories"
            }}
        >
            <React.StrictMode>
                <RouterProvider router={router} />
            </React.StrictMode>
        </Auth0Provider>
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
