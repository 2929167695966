import {Button, Flex, Layout} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import React from "react";
import Webcam from "react-webcam";

const { Content } = Layout;

const webcamLayoutStyle = {
    overflow: 'hidden',
    width: '100dvw',
    minHeight: '100dvh',
    backgroundColor: '#000000'
};

export default function MemoryWebcam({onCaptureClick, onCloseClick, webcamRef, orientation, width, height}: {onCaptureClick: () => void, onCloseClick: () => void, webcamRef: React.RefObject<Webcam>, orientation: string, width: number, height: number}) {
    return <Layout style={webcamLayoutStyle}>
        <Content>
            <Flex gap="middle" vertical justify="center" align="center" style={{height: "100dvh"}}>
                <Webcam
                    audio={false}
                    width={width}
                    height={width*4/3}
                    ref={webcamRef}
                    forceScreenshotSourceSize={true}
                    screenshotFormat={"image/webp"}
                    imageSmoothing={false}
                    videoConstraints={{
                        width: 3024,
                        height: 4032,
                        facingMode: "environment"
                    }}
                    screenshotQuality={1}
                />
            </Flex>
        </Content>
        {orientation === "portrait-primary" && (
            <>
                <Button onClick={onCloseClick} shape="circle" icon={<CloseOutlined/>} style={{top: 50, right: 30, position: 'absolute', zIndex: 10}}></Button>
                <Button onClick={onCaptureClick} shape="circle" icon={""} style={{bottom: 100, right: width / 2 - 32, position: 'absolute', zIndex: 10, width: "64px", height: "64px" }}></Button>
            </>
        )}
        {orientation === "landscape-primary" && (
            <>
                <Button onClick={onCloseClick} shape="circle" icon={<CloseOutlined/>} style={{top: 30, right: 130, position: 'absolute', zIndex: 10}}></Button>
                <Button onClick={onCaptureClick} shape="circle" icon={""} style={{top: height / 2 - 10, right: 130, position: 'absolute', zIndex: 10 }}></Button>
            </>
        )}
    </Layout>
}