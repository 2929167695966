import {MemoryDto, MemoryTagDto} from "gastromemories-api-client";
import {Select, Button, Flex, Form, Input, Image, Space, Rate, Tag, Col, Spin, Row} from "antd";
import React from "react";

export enum MemoryFormMode {
    update = "update",
    create = "create"
}

export default function CreateMemoryForm({
                                             onSubmitClick,
                                             onCloseClick,
                                             memory,
                                             mode,
                                             onDeleteClick,
                                             memoryTags,
                                             capturedImageSrc
                                         }: {
    onSubmitClick: (values: any) => void,
    onCloseClick: () => void,
    memory: MemoryDto,
    mode: MemoryFormMode,
    onDeleteClick?: (memory: MemoryDto) => void,
    memoryTags: MemoryTagDto[],
    capturedImageSrc: string | null
}) {
    let title = ""
    const [form] = Form.useForm()

    if (mode === MemoryFormMode.update) {
        title = memory.title || ""
    } else if (mode === MemoryFormMode.create) {
        title = "Create Memory"
    } else {
        title = memory.title || ""
    }

    return <Form
        scrollToFirstError
        form={form}
        title={title}
        onFinish={(values) => onSubmitClick({...memory, ...values})}
        style={{paddingBlock: 16, width: "100%"}}
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        initialValues={{
            ...memory,
            tags: memory.tags?.map(tag => tag.name)
        }}
    >
        <Form.Item name="image" label="">
            <Image src={capturedImageSrc ?? memory.image?.transcodedUrl}
                   preview={{
                        src: capturedImageSrc ?? memory.image?.url,
                   }}
                   style={{width: "100%", objectFit: "cover"}}></Image>
        </Form.Item>
        <div style={{paddingLeft: "12px", paddingRight: "12px", width: "100%"}}>
            <Form.Item name="title" label="Title" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
                rules={[{type: 'array'}]}
            >
                <Select mode="tags" placeholder="Please select from existing tags">
                    {memoryTags.map(tag => (
                        <Select.Option key={tag.id} value={tag.name}>
                            {tag.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Row>
                {mode === MemoryFormMode.create && (
                    <>
                        <div>
                            Suggested tags:
                        </div>
                        <div>
                            {mode === MemoryFormMode.create && memory.image?.suggestedTags === undefined && (
                                <Spin spinning={true} size="small" style={{paddingLeft: "8px"}}/>
                            )}
                        </div>
                    </>
                )}

            </Row>
            {mode === MemoryFormMode.create && memory.image?.suggestedTags && memory.image?.suggestedTags?.length > 0 && (
                <Row>
                    <Space>
                        <Col>
                            {memory.image?.suggestedTags?.map(tag => (
                                <Tag onClick={() => {
                                    form.setFieldsValue({
                                        tags: [...form.getFieldValue("tags"), tag]
                                    })
                                }}>{tag}</Tag>
                            ))}
                        </Col>
                    </Space>
                </Row>
            )}
            <div></div>
            <Form.Item name="note" label="Note">
                <Input.TextArea rows={2}/>
            </Form.Item>

            <Form.Item name="tastinessScore" label="Tastiness">
                <Rate/>
            </Form.Item>
        </div>
        <Form.Item>
            <Flex justify={"center"}>
                <Space size="large">
                    {mode === MemoryFormMode.update && (
                        <>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                            <Button onClick={onCloseClick}>
                                Cancel
                            </Button>
                            {onDeleteClick && (
                                <Button onClick={() => onDeleteClick(memory)} danger={true}>
                                    Delete
                                </Button>
                            )}
                        </>
                    )}
                    {mode === MemoryFormMode.create && (
                        <>
                            <Button type="primary" htmlType="submit">
                                Create
                            </Button>
                            <Button onClick={onCloseClick}>
                                Cancel
                            </Button>
                        </>
                    )}
                </Space>
            </Flex>
        </Form.Item>
    </Form>
}