import React from "react";

export const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    fontSize: 16,
    fontWeight: 'bold',
};

export const contentStyle: React.CSSProperties = {
    height: 'auto',
    color: '#fff',
    padding: '0px'
};

export const layoutStyle = {
    overflow: 'hidden',
    width: '100dvw',
    minHeight: '100dvh',
};